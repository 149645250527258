@font-face {
  font-family: Rubic-Light;
  src: url("../src/assets/fonts/static/Rubik-Light.ttf");
}

@font-face {
  font-family: Rubic-Medium;
  src: url("../src/assets/fonts/static/Rubik-Medium.ttf");
}

@font-face {
  font-family: Rubic-Regular;
  src: url("../src/assets/fonts/static/Rubik-Regular.ttf");
}

@font-face {
  font-family: Rubic-Regular;
  src: url("../src/assets/fonts/static/Rubik-Regular.ttf");
}

@font-face {
  font-family: Rubic-Variable;
  src: url("../src/assets/fonts/Rubik-Italic-VariableFont_wght.ttf");
}

body {
  font-family: Rubic-Regular;
  font-size: 16px;
  height: 100%;
  margin: 0;
  background-color: #f0f4ff;
}
.react-datepicker__input-container input {
  text-align: center;
  border: none;
  height: 27px;
  width: 350px;
  background-color: transparent;
  border: 1px solid gray;
  border-radius: 5px;
  /* &:focus {
    outline: none;
  } */
}
.center {
  position: absolute;
  width: 100%;
  height: 50px;
  top: 20%;

  /* left: 50%; */
  /* margin-left: -50px;  */
  /* margin-top: -25px;  */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
