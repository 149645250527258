.rbc-header {
	background: '#79b8e4' !important;
	color:'white' !important;
	padding: '10px' !important;
	font-size:'12px';

}

.rbc-event {
	/* padding: 0 !important; */
	/* border-radius: 0 !important; */
	border: none !important;
	/* background-color: transparent !important; */
	box-shadow: "0px 0.5px 1px rgba(0, 0, 0, 0.25)" !important;
	margin-bottom: '5px' !important;
}

.rbc-event-label{
	display: none !important;
}

.rbc-day-slot .rbc-event .rbc-day-slot .rbc-background-event  {
	display: flex !important;
	flex-flow: 'wrap-reverse' !important;
	/* overflow: scroll !important; */
}

/* .rbc-events-container {
	background-color: red !important;
} */